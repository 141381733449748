:root {
  --bg-black-main: #030303;
  --bg-blue-secondary: rgb(240, 240, 244);
  --bg-transparent-black: rgba(0, 0, 0, 0.543);
  --bg-transparent-white-text: rgb(239, 235, 235);
}

body {
  height: 100vh;
}

input {
  max-width: 400px;
  margin: 0 auto;
}

#brandLogo {
  opacity: 0.9;
}

.homeLeft {
  min-height: 100vh;
  background-color: var(--bg-black-main);
}

#stepsInHome {
  background-color: var(--bg-transparent-black);
  color: var(--bg-transparent-white-text);
}

#stepsInHome p strong {
  text-decoration: underline;
}

.homeRigth p,
.homeRigth li {
  font-size: 1.4rem;
}

.homeRigth {
  background-color: rgba(180, 180, 180, 0.356);
  padding: 0 20px;
  height: 100vh;
}

.homeRigth h1 {
  font-size: 2rem;
  color: var(--bg-black-main);
}

.homeRigth p {
  color: white;
}

@media (max-width: 765px) {
  nav ul li a {
    color: white !important;
  }

  .navbar {
    background-color: black !important;
  }

  /*   #navbarNavDropdown {
      background-color: black;
    } */

  .homeRow {
    margin-bottom: auto;
    height: 100%;
  }

  .homeLeft {
    min-height: 25vh;
  }

  .homeRigth p,
  .homeRigth li {
    font-size: 1.2rem;
  }

  .homeRigth {
    height: 75vh !important;
  }
}

/* AREA NOTIFICACIÃ“N */
#notification-area {
  border: 1px solid rgb(201, 201, 207);
  background-color: rgba(255, 255, 255, 0.689);
}

#notification-area h3 {
  color: var(--bg-black-main);
}

#notification-area a,
#notification-area button {
  /* text-decoration: none;
     background-color: var(--bg-black-main);
     border-radius: 0.3rem;
     padding: 12px 0;
     color: rgb(186, 186, 240);
     display: block; */
  font-size: 1.2rem;
}

@media (max-width: 765px) {
  #notification-area {
    margin-top: 1.5rem;
  }
}
