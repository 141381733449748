.sellerPanel table thead {
  font-size: 0.8rem;
}

.sellerPanel .idTableField {
  min-width: 50px !important;
}

.sellerPanel .bi-trash3:hover {
  color: black !important;
  text-decoration-line: underline;
  border-radius: 100%;
  box-shadow: 0 0 2px 2px rgb(185, 184, 184);
}
.sellerPanel .bi-pencil-square:hover {
  border-radius: 100%;
  box-shadow: 0 0 2px 2px rgba(197, 101, 101, 0.507);
  color: rgb(122, 41, 21) !important;
}
