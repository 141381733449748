body,
html {
  height: stretch;
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif !important;
}

body,
html {
  overflow: hidden !important;
}

@media (max-width: 765px) {
  body {
    overflow: scroll !important;
  }
}
