#notFoundPage {
  background-color: black;
  height: 100vh;
}

#notFoundPage img {
  width: 70%;
}

#notFoundPage h1 {
  color: white;
  font-size: 2rem;
}

@media (max-width: 765px) {
  #notFoundPage h1 {
    color: white;
    font-size: 1.4rem;
    padding: 0 20px;
  }
}
