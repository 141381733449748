/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
#largeMenuToggleContainer {
  z-index: 1000;
  background-color: #090930;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
#largeMenuToggleContainer .largeMenuToggleButton {
  color: white;
  font-size: 3rem !important;
  transition: all ease-in-out 0.3s;
}

#largeMenuToggleContainer .largeMenuToggleButton.active {
  transform: rotate(90deg);
  transform-origin: center;
}

.offcanvas {
  transition: all ease-in-out 0.5s !important;
}

.offCanvasButton {
  font-size: 3rem;
  color: white;
}

.logoNavbar {
  width: 200px;
}

.offCanvasMenuList {
  list-style: none;
  padding-left: 5px;
}

.offCanvasMenuList li {
  display: flex;
  align-items: center;
}

.offCanvasMenuList li:hover {
  color: black;
  font-weight: bold;
}

a {
  text-decoration: none;
  color: rgb(44, 42, 42);
}

a:hover {
  text-decoration: none;
  color: rgb(44, 42, 42);
  font-weight: bold;
}

#homeRight {
  padding-top: 105px !important;
  padding-bottom: 25px;

  overflow-y: scroll !important;
  height: 100vh;
}

.welcomeTitle {
  font-size: 2rem !important;
}

.customerDashboard h1 {
  font-size: 2rem !important;
  margin-right: 10px;
}

.customerDashboard .helpTip {
  font-size: 1.3rem !important;
}

.myNewItemButtonList {
  padding: 10px 20px;
  margin: 10px 0;
  width: 100% !important;
  font-size: 1rem;
}

.customerDashboard .myButtonList {
  padding: 10px 20px;
  margin: 10px 0;
  width: 100% !important;
  font-size: 1rem;
}

.customerDashboard .myButtonList i {
  font-size: 1.2rem !important;
  margin-right: 10px;
}

.customerDashboard .serviceData i {
  font-size: 1rem;
  margin-left: 2px;
}

.customerDashboard .serviceData ul {
  list-style: none;
  padding: 0;
  font-size: 0.8rem;
  margin-left: 2px;
}

.customerLogs {
  background-color: whitesmoke;
}

.customerLogs p {
  font-size: 1.1rem !important;
  color: black !important;
  font-size: 0.8rem;
  margin-left: 2px;
  margin-bottom: 0;
}
.customerEstates .bi-trash3,
.customerVehicles .bi-trash3 {
  bottom: 16px;
  cursor: pointer;
}

.customerVehicles h1,
.customerEstates h1 {
  font-size: 2rem !important;
}

.customerVehicles .myButtonList,
.customerEstates .myButtonList {
  padding: 10px 20px;
  margin: 10px 0;
  width: 100% !important;
  font-size: 1rem;
}

.customerVehicles .serviceData i,
.customerEstates .serviceData i {
  font-size: 1rem;
  margin-left: 2px;
}

.customerVehicles .serviceData p,
.customerEstates .serviceData p {
  font-size: 1.1rem !important;
  color: black !important;
  font-size: 0.8rem;
  margin-left: 2px;
}

.customerVehicles h1,
.customerProfile h1,
.customerEstates h1 {
  text-align: center;
}

.customerVehicles .addVehicleBtn,
.customerEstates .addVehicleBtn {
  color: green;
  font-size: 3rem;
}

.customerVehicles .addVehicleBtn:hover,
.customerEstates .addVehicleBtn:hover {
  color: rgb(24, 29, 18) !important;
}

.customerVehicle p,
.customerProfile p,
.customerEstate p {
  font-size: 1.1rem !important;
  color: black !important;
  font-size: 0.8rem;
  margin-left: 2px;
}

.customerVehicle .myButtonList,
.indexStepTwo .myButtonList,
.customerEstate .myButtonList {
  padding: 10px 20px;
  margin: 10px 0;
  width: 100% !important;
  font-size: 1rem;
  text-align: start;
}

.indexStepTwo .myButtonList {
  text-align: center;
}

.customerVehicle .confirmButton,
.customerRegister .confirmButton,
.customerProfile .confirmButton,
.indexStepTwo .confirmButton,
.customerEstate .confirmButton {
  padding: 10px 20px;
  margin: 10px 0;
  width: 100% !important;
  height: 50px !important;
  font-size: 1rem;
}

.customerVehicle .listSwitch,
.customerEstate .listSwitch {
  position: absolute;
  font-size: 1.5rem;
  right: 10px;
  top: 15px;
  cursor: pointer;
}

.customerVehicle .generalListSwitch,
.customerEstate .generalListSwitch {
  margin-right: 10px !important;
  font-size: 1.5rem;
  cursor: pointer;
}

.customerRegister .helpTip,
.customerVehicle .helpTip {
  position: absolute;
  bottom: 25px;
  right: 0;
  font-size: 1.3rem;
}

.indexStepTwo ul {
  padding: 0;
}

.indexStepTwo ul {
  text-decoration: none;
  padding: 0;
}

.offcanvas-body::-webkit-scrollbar,
#emailInRegister::-webkit-scrollbar {
  display: none !important;
}

.modal-content {
  background-color: rgb(236, 234, 234);
  border-radius: 0;
}

.productContainer {
  max-width: 370px;
}

.productContainer .productCard {
  background-color: #ffffff;
  position: relative;
}

.productDiscount {
  transform: rotate(-20deg);
  position: absolute;
  border-radius: 100%;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  text-align: center;
  padding: 15px;
  top: 23px;
  left: 20px;
  width: 75px;
  height: 75px;
  background-color: rgba(236, 211, 21, 0.894);
}

.productContainer .oldPrice {
  font-size: 1.5rem;
}

.productContainer .newPrice {
  font-size: 2.4rem;
}

.productContainer .productSMSBadge {
  font-size: 0.95rem !important;
  color: whitesmoke !important;
  background-color: rgb(39, 124, 180);
  margin-bottom: 0.5rem !important;
}
.productContainer .productVoiceBadge {
  color: whitesmoke !important;
  background-color: rgb(9, 150, 91);
  margin-bottom: 0.5rem !important;
}

.productContainer p {
  color: black !important;
  font-size: 0.8rem !important;
  font-weight: bold;
}

.productContainer .features ul {
  list-style: none;
}

.productContainer .features ul li {
  margin-bottom: 0.3rem;
  font-size: 1rem;
}

.productContainer .productCardNote {
  text-align: center;
  font-size: 0.57rem !important;
}

.checkout h1 {
  font-size: 2.2rem !important;
}

.checkout .details {
  background-color: rgb(248, 247, 247);
}

.checkout label {
  font-size: 1.1rem;
}

.checkout input[type="radio"] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

.checkout .productCardNote {
  font-size: 0.7rem !important;
}

.checkout .productQty {
  font-size: 1.4rem;
}

.thanksPage h1 {
  font-size: 2.2rem !important;
}

.thanksPage .code {
  background-color: rgba(247, 244, 244, 0.89);
  font-size: 2rem;
  border-radius: 5px;
  border: 1px solid rgb(224, 219, 219);
}

.thanksPage .copyButton {
  font-size: 2rem !important;
}

#faq p {
  font-size: 1.2rem;
}

#faq li {
  font-size: 1.2rem;
}

.faqButton {
  white-space: nowrap;
}
.faqAccordion {
  border-bottom: 1px solid rgb(216, 214, 214) !important;
  background-color: rgba(255, 255, 255, 0.399) !important;
  box-shadow: none !important;
}

.faqAccordion.selected {
  background-color: rgba(255, 255, 255, 0.904) !important;
}

.contactPage {
  background-color: whitesmoke;
}

.contactPage ul {
  list-style: none;
  padding-left: 0;
}

.contactPage ul li {
  font-size: 1rem;
  margin-bottom: 0.3rem;
}

@media (max-width: 767px) {
  .offcanvas-header {
    padding-bottom: 0 !important;
  }

  .offcanvas-body hr {
    margin: 12px 0;
  }

  .customerVehicle .myButtonList:hover,
  .customerVehicles .myButtonList {
    background-color: transparent;
    color: black;
    cursor: default;
  }
  .customerEstate .myButtonList:hover,
  .customerEstates .myButtonList {
    background-color: transparent;
    cursor: default;
    color: black;
  }
}

#whatsapp {
  background-color: black;
  position: fixed;
  padding: 0 3px;
  bottom: 10px;
  right: 0px;
  font-size: 45px;
  border-radius: 20px 0 0 20px;
}
#whatsapp:hover {
  background-color: #090930;
  position: fixed;
  padding: 0 3px;
  bottom: 10px;
  right: 0px;
  font-size: 45px;
  border-radius: 20px 0 0 20px;
}

#whatsapp {
  transition: all ease-in-out 0.5s;
}

#whatsapp .close-whatsapp {
  font-size: 23px;
}

#whatsapp .bi-whatsapp {
  border-left: 1px solid white !important;
}

#whatsapp.hide {
  right: -65px;
}

/* Cargador */

/* .loaderContainer {
  padding-top: 7rem;
} */

.l {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.o {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.4s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.a {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.6s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.d {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 0.8s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.i {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.n {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1.2s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.g {
  color: black;
  opacity: 0;
  animation: pass 2s ease-in-out infinite;
  animation-delay: 1.4s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

.d1 {
  color: black;
  opacity: 0;
  animation: pass1 2s ease-in-out infinite;
  animation-delay: 1.6s;
  letter-spacing: 0.5em;
  text-shadow: 2px 2px 3px #919191;
}

@keyframes pass {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pass1 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.AboutThisProyect .aboutPrimaryColor {
  background-color: #090930;
  color: rgb(227, 228, 233);
}

.AboutThisProyect .aboutPrimaryColor p {
  color: rgb(227, 228, 233);
}

.AboutThisProyect .aboutSecondaryColor {
  margin: 0;
  background-color: #cacfdc;
}
.AboutThisProyect .aboutSecondaryColor p {
  color: #1c1c23;
}

.AboutThisProyect h3 {
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 1rem;
}
