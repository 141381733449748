.dashboardMonthSelect {
padding: 5px 10px;
border-radius: 5px;
border-color: gray;
margin-left: 10px;
font-size: 1.2rem;
font-weight: 500;
}

#dashboard-container h3 {
    font-size: 1.3rem;
    padding: 5px 0;
}

#dashboard-container span {
    font-size: 3rem;
    font-weight: 500;
}

.dashboard_label1,
.dashboard_label2,
.dashboard_label3,
.dashboard_label4,
.dashboard_label5,
.dashboard_label6 {
  color: white;
  margin: 15px;
  padding: 10px;
  border-radius: 10px;
}


.dashboard_label1,
.dashboard_label2,
.dashboard_label3,
.dashboard_label4,
.dashboard_label5,
.dashboard_label6 {
  color: white;
  margin: 15px;
  padding: 10px;
  border-radius: 5px;
}

.dashboard_label1 {
  background-color: rgb(85, 85, 245);
}

.dashboard_label2 {
  background-color: rgb(250, 171, 24);
}

.dashboard_label3 {
  background-color: rgb(29, 161, 12);
}

.dashboard_label4 {
  background-color: rgb(192, 42, 37);
}
.dashboard_label5 {
  background-color: rgb(0, 0, 0);
}
.dashboard_label6 {
  background-color: rgb(131, 12, 161);
}

