:root {
  --bg-blue-main: #090930;
  --bg-blue-secondary: rgb(240, 240, 244);
  --bg-transparent-black: rgba(0, 0, 0, 0.543);
  --bg-transparent-white-text: rgb(239, 235, 235);
}

.homeRigth::-webkit-scrollbar {
  display: none;
}

.formContainer {
  min-width: 280px;
  max-width: 600px;
  position: relative;
}

.customerLoginInput {
  width: 100%;
}

.loginErrorContainer {
  position: absolute;
  bottom: -3rem;
  font-size: 1.1rem !important;
}

#brandLogo {
  opacity: 0.9;
}

.homeLeft {
  height: 100vh;
  background-color: var(--bg-blue-main);
}

#stepsInHome {
  background-color: var(--bg-transparent-black);
  color: var(--bg-transparent-white-text);
}

#stepsInHome p strong {
  text-decoration: underline;
}

.homeRigth p,
.homeRigth li {
  font-size: 1.4rem;
}

.homeRigth {
  background-color: rgb(233, 233, 233);
  overflow: scroll;
}

.fullScreenNavbar {
  position: absolute;
  top: 0;
  width: 100%;
}

.homeRigth h1 {
  font-size: 2.7rem;
  color: var(--bg-blue-main);
}

@media (max-width: 765px) {
  nav ul li a {
    color: white !important;
  }

  .homeRow {
    margin-bottom: auto;
    height: 100%;
  }

  .homeRigth p,
  .homeRigth li {
    font-size: 1.2rem;
  }

  .homeRigth {
    min-height: 100vh !important;
  }
}

/* AREA NOTIFICACIÃ“N */
#notification-area {
  border: 1px solid rgb(201, 201, 207);
  background-color: rgba(255, 255, 255, 0.689);
}

#notification-area h3 {
  color: var(--bg-blue-main);
}

#notification-area a,
#notification-area button {
  font-size: 1.2rem;
}

@media (max-width: 765px) {
  #notification-area {
    margin-top: 1.5rem;
  }
}
